import React, { memo, useState, useEffect } from 'react';
import { public_restricted_routes, public_routes, private_routes } from '@configs/Router';
import _ from 'lodash';
import { removeLoginAccess } from '@utils/ApiAction';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme, makeStyles } from '@mui/styles';
import { AppBar, Toolbar, IconButton, Menu, MenuItem, Tooltip, Avatar, Link } from '@mui/material';
import { Link as RouterLink, useLocation, useNavigate, matchRoutes, useParams } from 'react-router-dom';

import { changeLanguage } from '@actions';
import moment from 'moment-timezone';
import { reducePaths, storePaths } from '@utils/Tools';

import { IoLanguage } from "react-icons/io5";
import { FiChevronLeft } from "react-icons/fi";

const systemLanguages = ['en', 'cn', 'zhtw', 'id', 'kr', 'jp', 'th', 'vi'];

const INITIAL_STATE = { showTutorBtn: false, showBackBtn: false, backUrl: null, tutorUrl: null, showLogo: true, noHeader: false, floatSubheader: false };

const TitleBar = memo((props) => {

    const theme = useTheme();
    const styles = useStyles();
    const dispatch = useDispatch();
    const { i18n, t } = useTranslation();
    const [option, setOption] = useState(INITIAL_STATE);
    const [anchorEl, setAnchorEl] = useState(null);
    const { accessToken } = useSelector(state => state.general);
    const { email, name } = useSelector(state => state.user);
    const open = anchorEl?.attributes?.['data-ref']?.value;//Boolean(anchorEl);
    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event = null) => {
        if (event) event.stopPropagation()
        setAnchorEl(null);
    };
    let location = useLocation();
    let navigate = useNavigate();
    let from = location.state?.from?.pathname;
    let current_path = location?.pathname;
    let { id } = useParams();

    const [serverTime, setServerTime] = useState("");

    useEffect(() => {
        let public_restricted_detail = matchRoutes(public_restricted_routes, current_path)?.[0]?.['route'];
        let public_detail = matchRoutes(public_routes, current_path)?.[0]?.['route'];
        let private_detail = matchRoutes(private_routes, current_path)?.[0]?.['route'];

        if (_.size(public_restricted_detail) > 0) {
            setOption({ ...option, showBackBtn: public_restricted_detail?.showBackBtn, showTutorBtn: public_restricted_detail?.showTutorBtn, showLogo: public_restricted_detail?.showLogo, tutorUrl: public_restricted_detail?.tutorUrl, backUrl: public_restricted_detail?.backUrl, backTitle: t(public_restricted_detail?.backTitle), noHeader: public_restricted_detail?.noHeader, floatSubheader: public_restricted_detail?.floatSubheader });

        } else if (_.size(public_detail) > 0) {
            setOption({ ...option, showBackBtn: public_detail?.showBackBtn, showTutorBtn: public_detail?.showTutorBtn, showLogo: public_detail?.showLogo, tutorUrl: public_detail?.tutorUrl, backUrl: public_detail?.backUrl, backTitle: t(public_detail?.backTitle), noHeader: public_detail?.noHeader, floatSubheader: public_detail?.floatSubheader });

        } else if (_.size(private_detail) > 0) {
            setOption({ ...option, showBackBtn: private_detail?.showBackBtn, showTutorBtn: private_detail?.showTutorBtn, showLogo: private_detail?.showLogo, tutorUrl: private_detail?.tutorUrl, backUrl: private_detail?.backUrl, backTitle: t(private_detail?.backTitle), noHeader: private_detail?.noHeader, floatSubheader: private_detail?.floatSubheader });

        } else {
            setOption(INITIAL_STATE);
        }

    }, [current_path, t]);

    useEffect(() => {
        moment.tz.setDefault("Asia/Kuala_Lumpur");
        let secTimer = setInterval(() => {
            setServerTime(moment().format("YYYY-MM-DD HH:mm:ss"))
        }, 1000)

        return () => clearInterval(secTimer);
    }, [])

    const changeSystemLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(changeLanguage(lang));
        handleClose();
    };

    useEffect(() => {
        if (i18n.language == "ur") {
            document.documentElement.dir = 'rtl'
        } else {
            document.documentElement.dir = 'ltr';
        }
        document.documentElement.lang = i18n.language;
        // eslint-disable-next-line
    }, [i18n.language]);

    const goBack = () => {
        if (_.size(location?.state?.from) > 0) {
            let path = _.size(location?.state?.from) > 0 ? _.last(location?.state?.from) : '/';
            let pathListing = reducePaths(location);
            let urlLink = path?.pathname;
            if (path?.search !== '') {
                urlLink = urlLink + path?.search;
            }
            navigate(urlLink, { state: { from: pathListing } });
        } else {
            navigate('/');
        }
    }

    const logout = () => {
        removeLoginAccess();
        navigate('/landing');
    };

    // CHATBOT
    // useEffect(() => {
    //     const head = document.querySelector("head");
    //     const script = document.createElement("script");
    //     window.$crisp=[];window.CRISP_WEBSITE_ID="d55d5s335-9sb8c-4d1s7-805sf-4d7s5bb076bf5";
    //     script.setAttribute("src", "https://client.crisp.chat/l.js");
    //     head.appendChild(script);

    //     var css = '#crisp-chatbox span.cc-imbb.cc-qfnu {  background-color: #404b7d82 !important; backdrop-filter: blur(5px); -webkit-backdrop-filter: blur(5px)!important; box-shadow: 3px 3px 3px 0 #00000042 !important }';

    //     var style = document.createElement('style');
    //     head.appendChild(style);

    //     style.type = 'text/css';
    //     if (style.styleSheet) {
    //         // This is required for IE8 and below.
    //         style.styleSheet.cssText = css;
    //     } else {
    //         style.appendChild(document.createTextNode(css));
    //     }

    //     return () => {
    //         head.removeChild(script);
    //     };
    // }, []);

    return (
        option?.noHeader === true ?
            <div className={` w-full p-lr-26 p-t-20 p-b-10 ${option?.floatSubheader === true ? 'pos-absolute flex-r-m' : 'pos-relative flex-sb-m'}`} style={{zIndex:2}}>
                {option?.showBackBtn &&
                    <div style={{ zIndex: 3 }}>
                        {option?.backUrl ?
                            <Link underline='none' to={option?.backUrl} component={RouterLink} state={{ from: storePaths(location) }} className='flex-m' style={{ color: '#fff' }}>
                                <FiChevronLeft /><p className='p-l-10 fs-14'>{option?.backTitle}</p>
                            </Link>
                            : <div className='flex-m pointer txt-white' onClick={goBack}>
                                <FiChevronLeft /><p className='p-l-10 fs-12'>{option?.backTitle}</p>
                            </div>}

                    </div>
                }
                {
                    option?.showLogo &&

                    <div>
                        <Link component={RouterLink} to={accessToken ? "/" : "/login"} underline='none' state={{ from: storePaths(location) }} >
                            <img src='/logo.png' alt="logo" style={{ width: 60 }} />
                        </Link>
                    </div>
                }
                <div className='flex-m' style={{gap:10}}>
                    <div className='txt-right txt-white p-r-5 fs-12 lh-12' style={{ letterSpacing: '-0.2px' }}>
                        <p>{t('general.serverTime')}</p>
                        <p className='p-t-5'>{serverTime}</p>
                    </div>
                    <Tooltip title={t('general.language')} arrow><IconButton data-ref="language" onClick={handleClick} ><IoLanguage className="img-style" style={{ pointerEvents: "none" }} /></IconButton></Tooltip>
                    <Menu
                        anchorEl={anchorEl}
                        open={open === "language"}
                        onClose={handleClose}
                        onClick={handleClose}
                        disableScrollLock={true}
                        slotProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        {
                            _.map(systemLanguages, (_languages, index) => (
                                <MenuItem key={index} onClick={() => changeSystemLanguage(_languages)} >{t(`general.${_languages}`)}</MenuItem>
                            ))
                        }
                    </Menu>
                </div>
            </div>
            :
            <div>
                <AppBar position='fixed' sx={{ maxWidth: '100%', width: 444, right: 'auto' }}>
                    <Toolbar className='flex-sb-m w-full'>
                        {
                            option?.showLogo &&

                            <div>
                                <Link component={RouterLink} to={accessToken ? "/" : "/login"} underline='none' state={{ from: storePaths(location) }} >
                                    <img src='/logo.png' alt="logo" style={{ width: 60 }} />
                                </Link>
                            </div>
                        }
                        <div>
                            <Tooltip title={t('general.language')} arrow><IconButton data-ref="language" onClick={handleClick} ><IoLanguage className="img-style" style={{ pointerEvents: "none" }} /></IconButton></Tooltip>
                            <Menu
                                anchorEl={anchorEl}
                                open={open === "language"}
                                onClose={handleClose}
                                onClick={handleClose}
                                disableScrollLock={true}
                                slotProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '&:before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                {
                                    _.map(systemLanguages, (_languages, index) => (
                                        <MenuItem key={index} onClick={() => changeSystemLanguage(_languages)} >{t(`general.${_languages}`)}</MenuItem>
                                    ))
                                }
                            </Menu>
                            {
                                accessToken &&
                                <IconButton data-ref="profile" onClick={handleClick} style={{ fontSize: 14, textTransform: "capitalize" }}><Avatar sx={{ width: 32, height: 32, marginRight: 1, pointerEvents: "none" }}>{name?.charAt(0).toUpperCase()}</Avatar>{name}</IconButton>
                            }
                            {
                                accessToken &&
                                <Menu
                                    anchorEl={anchorEl}
                                    open={open === "profile"}
                                    onClose={handleClose}
                                    onClick={handleClose}
                                    disableScrollLock={true}
                                    slotProps={{
                                        elevation: 0,
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 1.5,
                                            '&:before': {
                                                content: '""',
                                                display: 'block',
                                                position: 'absolute',
                                                top: 0,
                                                right: 14,
                                                width: 10,
                                                height: 10,
                                                transform: 'translateY(-50%) rotate(45deg)',
                                                zIndex: 0,
                                            },
                                        },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    <MenuItem onClick={() => navigate('/setting/profile')} >{t('title.profile')}</MenuItem>
                                    <MenuItem onClick={logout} >{t('button.logout')}</MenuItem>
                                </Menu>
                            }
                        </div>
                    </Toolbar>
                    <div className='flex-sb-m p-lr-15 p-tb-10'>
                        {option?.showBackBtn &&
                            <div style={{ zIndex: 3 }}>
                                {option?.backUrl ?
                                    <Link underline='none' to={option?.backUrl} component={RouterLink} state={{ from: storePaths(location) }} className='flex-m' style={{ color: '#fff' }}>
                                        <FiChevronLeft /><p className='p-l-10 fs-14'>{option?.backTitle}</p>
                                    </Link>
                                    : <div className='flex-m pointer txt-white' onClick={goBack}>
                                        <FiChevronLeft /><p className='p-l-10 fs-14'>{option?.backTitle}</p>
                                    </div>}

                            </div>
                        }
                        <div></div>
                        <div className='txt-right txt-white p-r-5 fs-12 lh-12' style={{ letterSpacing: '-0.2px' }}>
                            <p>{t('general.serverTime')}</p>
                            <p className='p-t-5'>{serverTime}</p>
                        </div>
                        {/* {option?.showTutorBtn &&
                                <Link className='p-b-15' component={RouterLink} to={option?.tutorUrl} underline='none' state={{ from: storePaths(location) }} >
                                    <div className='button-theme pointer txt-center' style={{ width: 100 }}>{t('title.tutorial')}</div>
                                </Link>
                            } */}
                    </div>
                </AppBar>
                {
                    option?.showBackBtn &&
                    <div className='m-t-140'></div>
                }
            </div>
    )
});

const useStyles = makeStyles((theme) => ({
}));

export default TitleBar;