import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

function ScrollToTop({ history }) {
    const isMountedRef = useRef();
    const { pathname } = useLocation();
    // console.log(pathname)
    useEffect(() => {
        isMountedRef.current = true;
        window.scrollTo(0, 0);
        return () => { 
            isMountedRef.current = false;
        }
    }, [pathname]);
}

export default ScrollToTop;