// AUTH
import Login from '@auths/Login';
import Register from '@auths/Register';
import ForgetPassword from '@auths/ForgetPassword';
import ResetPassword from '@auths/ResetPassword';
import SplashScreen from '@auths/SplashScreen';

// GUEST
import Maintenance from '@pages/Maintenance';

// USER
import Home from '@pages/Home';
import Profile from '@pages/Profile/BasicInfoProfile';
import ProfilePassword from '@pages/Profile/PasswordProfile';
import ProfileSecurityPassword from '@pages/Profile/SecurityPasswordProfile';
import Setting from '@pages/Setting';
// import Deposit from '@pages/Deposit';
// import Withdraw from '@pages/Withdraw';
import Genealogy from '@pages/Genealogy';
import EmailVerification from '@pages/Profile/EmailVerification';
import Wallet from '@pages/Wallet';
import WalletListing from '@pages/Wallet/WalletListing';
import CommissionListing from '@pages/Wallet/CommissionListing';
// import WalletDashboard from '@pages/WalletDashboard';
import Deposit from '@pages/Wallet/Deposit';
import Withdrawal from '@pages/Wallet/Withdrawal';
import GameTopUp from '@pages/Wallet/GameTopUp';
import Transfer from '@pages/Wallet/Transfer';
import CryptoAddress from '@pages/Profile/CryptoAddress';
import TransactionHistory from '@pages/Wallet/TransactionHistory';
import PurchasePlan from '@pages/Package/PurchasePlan';
import PlanHistories from '@pages/Package/PlanHistory';
import Stake from '@pages/Stake';
import Staking from '@pages/Stake/Staking';
import ContactUs from '@pages/Setting/ContactUs';
import Convert from '@pages/Wallet/Convert';

//TUTORIAL
import TutorGameTopUp from '@pages/Tutorial/TutorGameTopUp';
import TutorFundIn from '@pages/Tutorial/TutorFundIn';
import TutorTransfer from '@pages/Tutorial/TutorTransfer';
import TutorCashOut from '@pages/Tutorial/TutorCashOut';




// ONLY CAN ACCESS AFTER LOGIN
export const private_routes = [
    {
        path: "/",
        component: <Home />,
        showTutorBtn: false,
        showBackBtn : false,
        showLogo: true,
    },
    {
        path: "/stake",
        component: <Stake/>,
        showTutorBtn: false,
        showBackBtn : false,
        showLogo: true,
    },
    {
        path: "/staking",
        component: <Staking/>,
        showTutorBtn: false,
        showBackBtn : true,
        showLogo: false,
        noHeader: true,
    },
    // SETTING  
    {
        path: "/setting/profile",
        component: <Profile />,
        title: 'title.profile',
        showLogo: false,
        showBackBtn : true,
        backTitle: 'title.profile',
        // showExtendMargin: true,
    },
    {
        path: "/setting",
        component: <Setting />,
        title: 'title.setting',
        showLogo: true,
    },
    {
        path: "/setting/password",
        component: <ProfilePassword/>,
        title: 'title.password',
        showLogo: false,
        showBackBtn : true,
        showTutorBtn: false,
        backTitle: 'title.password',
        // showExtendMargin: true,
    },
    {
        path: "/setting/security-password",
        component: <ProfileSecurityPassword />,
        title: 'title.securityPassword',
        showLogo: true,
        showBackBtn : false,
        showTutorBtn: false,
        backTitle: 'title.securityPassword',
        // showExtendMargin: true,
    },
    {
        path: "/setting/crypto-address",
        component: <CryptoAddress />,
        title: 'title.cryptoAddress',
        showLogo: false,
        showBackBtn : true,
        showTutorBtn: false,
        backTitle: 'title.cryptoAddress',
        // showExtendMargin: true,
    },
    // GENEALOGY
    {
        path: "/community/:page",
        component: <Genealogy/>,
        exact: true,
        showLogo: true,
    },
    // WALLET
    {
        path: "/wallets/:page",
        component: <Wallet/>,
        exact: true,
        showLogo: true,
    },
    {
        path: "/wallet-listing",
        component: <WalletListing/>,
        exact: true,
        showTutorBtn: false,
        showBackBtn : true,
        tutorUrl : "/tutorial/fundIn",
        showLogo: false,
        // showExtendMargin: true,
        backTitle: 'title.wallet',
    },
    {
        path: "/commission-listing",
        component: <CommissionListing/>,
        exact: true,
        showTutorBtn: false,
        showBackBtn : true,
        tutorUrl : "/tutorial/fundIn",
        showLogo: false,
        // showExtendMargin: true,
        backTitle: 'title.wallet',
    },
    {
        path: "/wallet/deposit",
        component: <Deposit/>,
        exact: true,
        showTutorBtn: true,
        showBackBtn : true,
        tutorUrl : "/tutorial/fundIn",
        showLogo: false,
        // showExtendMargin: true,
        backTitle: 'wallet.deposit',
    },
    {
        path: "/:type/transactionHistory/:id",
        component: <TransactionHistory/>,
        exact: true,
        showTutorBtn: false,
        showBackBtn : true,
        showLogo: false,
        // showExtendMargin: true,
        backTitle: 'title.transactionHistory',
    },
    {
        path: "/wallet/game-topup/:id",
        component: <GameTopUp/>,
        exact: true,
        showLogo: false,
        showTutorBtn: true,
        showBackBtn : true,
        tutorUrl : "/tutorial/gameTopUp/1",
        // showExtendMargin: true,
        backTitle: 'wallet.gameTopup',
    },
    {
        path: "/wallet/withdrawal/:id",
        component: <Withdrawal/>,
        exact: true,
        showLogo: false,
        showTutorBtn: true,
        showBackBtn : true,
        tutorUrl : "/tutorial/cashOut/1",
        showExtendMargin: true,
        // backTitle: 'wallet.withdraw',
        noHeader: true,
    },
    {
        path: "/wallet/transfer/:id",
        component: <Transfer/>,
        exact: true,
        showLogo: false,
        showTutorBtn: true,
        showBackBtn : true,
        tutorUrl : "/tutorial/transfer/1",
        noHeader: true,
        // showExtendMargin: true,
        // backTitle: 'wallet.transfer'
    },
    {
        path: "/wallet/convert/:id",
        component: <Convert/>,
        exact: true,
        showLogo: false,
        showTutorBtn: true,
        showBackBtn : true,
        // tutorUrl : "/tutorial/convert/1",
        noHeader: true,
        // showExtendMargin: true,
        // backTitle: 'wallet.transfer'
    },
    {
        path: "/packages/purchase",
        component: <PurchasePlan/>,
        exact: true,
        showLogo: false,
        showTutorBtn: false,
        showBackBtn : true,
        backTitle: 'title.package'
    },
    {
        path: "/packages/histories",
        component: <PlanHistories/>,
        exact: true,
        showLogo: false,
        showTutorBtn: false,
        showBackBtn : true,
        backTitle: 'title.planHistories'
    },
    {
        path: "/contact-us",
        component: <ContactUs />,
        title: 'title.contactUs',
        showBackBtn : true,
        showLogo: false,
        noHeader: true,
        backTitle: 'title.contactUs'
    }
];

// CAN ACCESS BEFORE OR AFTER LOGIN
export const public_routes = [
    {
        path: "/email-verification",
        component: <EmailVerification />,
        title: 'title.emailVerification',
    },
    {
        path: "/maintenance",
        component: <Maintenance/>,
        title: 'title.maintenance',
        showLogo: true,
        noHeader: true,
    },
    {
        path: "/tutorial/gameTopUp/:id",
        component: <TutorGameTopUp />,
        title: 'title.tutorial',
        showBackBtn : true,
        showLogo: false,
        backTitle: 'title.tutorial'
    },
    {
        path: "/tutorial/fundIn",
        component: <TutorFundIn />,
        title: 'title.tutorial',
        showBackBtn : true,
        showLogo: false,
        backTitle: 'title.tutorial'
    },
    {
        path: "/tutorial/transfer/1",
        component: <TutorTransfer />,
        title: 'title.tutorial',
        showBackBtn : true,
        showLogo: false,
        backTitle: 'title.tutorial'
    },
    {
        path: "/tutorial/cashOut/1",
        component: <TutorCashOut />,
        title: 'title.tutorial',
        showBackBtn : true,
        showLogo: false,
        backTitle: 'title.tutorial'
    }
];

// ONLY CAN ACCESS BEFORE LOGIN
export const public_restricted_routes = [
    {
        path: "/register",
        component: <Register />,
        title: 'title.register',
        showBackBtn: true,
        noHeader: true,
    },
    {
        path: "/login",
        component: <Login />,
        title: 'title.login',
        showBackBtn: true,
        noHeader: true,
    },
    {
        path: "/forgot-password",
        component: <ForgetPassword />,
        title: 'forgetPassword.title',
        showBackBtn: true,
        noHeader: true,
    },
    {
        path: "/reset-password",
        component: <ResetPassword />,
        title: 'forgetPassword.title',
        showBackBtn: true,
        noHeader: true,
    },
    {
        path: "/landing",
        component: <SplashScreen />,
        title:'title.splashScreen',
        noHeader: true,
        floatSubheader: true,
    },
];

// FOR IMPORTED MEMBERS, FORCE CHANGE EMAIL
export const imported_user_restricted_routes = [
    {
        path: "/setting/profile",
        component: <Profile />,
        title: 'title.profile',
        
    },
];