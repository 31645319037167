import { createStore, combineReducers, applyMiddleware } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import reducers from '../reducers';

const logger = createLogger();

const persistConfig = {
    key: 'root',
    storage
}

const combinedReducer = combineReducers(reducers);
const persistedReducer = persistReducer(persistConfig, combinedReducer);
const middlewareApplied = process.env.REACT_APP_NODE_ENV === 'development' ? applyMiddleware(thunk, logger) : applyMiddleware(thunk);


const store = createStore(
    persistedReducer,
    {},
    middlewareApplied
);

let persistor = persistStore(store);

export { store, persistor };
