// GENERAL
export const CHANGE_LANGUAGE = 'change_language';
export const RESET_CODE_STATE = 'reset_code_state';
export const AUTH_SUCCESS = 'auth_success';
export const AUTH_FAIL = 'auth_fail';
export const UPDATE_IMPORTED = 'update_imported';

// USER
export const STORE_PROFILE = 'store_profile';
export const RESET_PROFILE = 'reset_profile';
export const STORE_PLAYER_ID = 'store_player_id';
