import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { Box, Button, Container, Link, Hidden } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { storePaths } from '@utils/Tools';

export default function SplashScreen() {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();
    let location = useLocation();

    return (
        <Box>
        {/* style={{backgroundImage:'url(/images/auth/base2.png)', backgroundRepeat:'no-repeat', backgroundPosition:'center bottom', backgroundSize:'contain'}} */}
            <div className=' pos-relative min-h-100vh' style={{backgroundImage:'url(/images/auth/bkg-landing3.png)', backgroundRepeat:'no-repeat', backgroundPosition:'center top', backgroundSize:'contain'}}>
                <div className='w-full'>
                    <div className='p-t-70 pos-relative' style={{ margin: '0 auto', width: '95%', zIndex: 1 }}>
                        <img src='/images/auth/graphic-landing4c.png' alt="graphic" />
                    </div>
                    <div style={{ margin: '-160px auto 0' }}>
                        <img src='/images/auth/base3.png' alt="graphic" className='w-full' />
                    </div>
                </div>

                <div className='pos-absolute sliderLeft' style={{zIndex:3,width:50, top:65, left:55}}>
                    <img src='/images/auth/elements/circle-purple.png' alt="graphic" className='w-full' />
                </div>
                <div className='pos-absolute floatingY' style={{zIndex:3,width:50, top:120, right:95,animationDuration:'4s'}}>
                    <img src='/images/auth/elements/minus.png' alt="graphic" className='w-full' />
                </div>

                <div className='pos-absolute floatingYup' style={{zIndex:3,width:55, top:370, left:40,animationDuration:'4s', }}>
                    <img src='/images/auth/elements/plus.png' alt="graphic" className='w-full' />
                </div>
                <div className='pos-absolute sliderRight' style={{zIndex:3,width:40, top:470, right:65}}>
                    <img src='/images/auth/elements/circle-blue.png' alt="graphic" className='w-full' />
                </div>

                <div className='pos-absolute' style={{zIndex:3, top: '47%', left: 'calc(50% - 40px)', filter:'drop-shadow(1px 3px 3px #fff3)'}}>
                    <div className='flex-col-c-m'>
                    <div className='animate1' style={{ width: 30, lineHeight:0 }}>
                        <img src='/images/auth/elements/btn-top.png' alt="graphic" className='w-full' />
                    </div>
                    <div className='flex-m' style={{lineHeight:0, gap:20}}>
                        <div className='animate4' style={{ width: 30, lineHeight:0}}>
                            <img src='/images/auth/elements/btn-left.png' alt="graphic" className='w-full' />
                        </div>
                        <div className='animate2' style={{ width: 30, lineHeight:0}}>
                            <img src='/images/auth/elements/btn-right.png' alt="graphic" className='w-full' />
                        </div>
                    </div>
                    <div className='animate3' style={{ width: 30, lineHeight:0}}>
                        <img src='/images/auth/elements/btn-bottom.png' alt="graphic" className='w-full' />
                    </div>
                    </div>
                   
                </div>
            </div>
            {/* <div className=' pos-relative min-h-100vh' style={{backgroundImage:'url(/images/auth/bkg-landing2.png)', backgroundRepeat:'no-repeat', backgroundPosition:'center top', backgroundSize:'contain'}}>
                <div className='w-full'>
                    <div className='p-t-180 p-b-20' style={{ width: 200, margin: '0 auto',  maxWidth: '64vw', filter: 'drop-shadow(0 4px 4px #0009)', }}>
                        <img src='/logo.png' alt="logo-text" className='w-full' />
                    </div>
                    <div className='p-t-164 pos-relative' style={{ margin: '-430px auto 0', width: '95%', zIndex:1 }}>
                        <img src='/images/auth/graphic-landing3.png' alt="graphic" />
                    </div>
                    <div style={{margin:'-190px auto 0'}}>
                    <img src='/images/auth/base3.png' alt="graphic" className='w-full' />
                    </div>
                </div>
            </div> */}
            <Container maxWidth="xs" className="flex-col " style={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex:3, background:'linear-gradient(rgba(18, 27, 62, 0) 0%, rgb(11 11 27) 40%)' }}>
                <Box className="p-tb-30 p-lr-20 txt-center w-full">
                    <Link component={RouterLink} to={`/login`} state={{ from: storePaths(location) }}>
                        <Button variant="contained" color="primary" style={{ marginTop: 20 }} fullWidth>
                            <p className='txt-upper' style={{ fontWeight: 600 }}>{t('button.login')}</p>
                        </Button>
                    </Link>
                    <Link component={RouterLink} to={`/register`} state={{ from: storePaths(location) }}>
                        <Button variant="outlined" style={{ marginTop: 20 }} fullWidth>
                            <p className='txt-upper' style={{ fontWeight: 600 }}>{t('general.register')}</p>
                        </Button>
                    </Link>
                </Box>
            </Container>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({

}));