import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { useDispatch } from 'react-redux';
import { authFail, authSuccess } from '@actions';
import { reducePaths, storePaths } from '@utils/Tools';
import _ from 'lodash';

// MUI
import { Box, Button, Container, Drawer, Grid, IconButton, InputAdornment, Tab, Tabs, TextField, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { FiEye, FiEyeOff, FiUser, FiLock } from 'react-icons/fi';

export default function Login() {
    const { t } = useTranslation();
    const theme = useTheme();
    let navigate = useNavigate();
    let location = useLocation();
    const { addAlert, setLoading } = useNotificationLoading();
    const dispatch = useDispatch();

    const [state, setState] = useState({
        username: '',
        password: '',
        showPassword: false,
        type: 'web',
    });
    const [inputErrors, setInputErrors] = useState({});

    const handleClickShowPassword = () => {
        setState({
            ...state,
            showPassword: !state.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const loginUser = (event) => {
        setLoading(true);
        event.preventDefault();
        postUrl('/login', state).then(response => {
            setLoading(false);
            if (response.status) {
                dispatch(authSuccess(response.data));
                if (_.size(location?.state) > 0) {
                    let pathListing = reducePaths(location);
                    let path = _.size(pathListing) > 0 ? _.last(pathListing) : '/';
                    let urlLink = path?.pathname;
                    if (path?.search !== '') {
                        urlLink = urlLink + path?.search;
                    }
                    navigate(urlLink, { state: { from: pathListing } });
                } else {
                    navigate('/');
                }
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            dispatch(authFail());
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }


    // useEffect(() => {
    //     if (location.pathname === '/login') {
    //         setTabValue(0)
    //     } else if (location.pathname === '/register') {
    //         setTabValue(1)
    //     } else {
    //         setTabValue(0)
    //     }
    //     // eslint-disable-next-line
    // }, [location.pathname]);

    return (
        <Box className="min-h-100vh pos-relative flex-col flex-m" style={{ justifyContent: 'flex-start' }}>
            <div className='flex-c-m w-full pos-relative' style={{ marginTop: 0, height: 300, backgroundImage: 'url("/images/auth/background2.png")', backgroundSize: 'cover', backgroundPosition: 'center bottom', backgroundRepeat: 'no-repeat' }}>
                <img src="/logo.png" className='m-b-84' style={{ height: 115, width: 'auto' }} alt="logo" />
                <div className='pos-absolute floatingYup' style={{zIndex:3,width:35, bottom:70, left:30, animationDuration:'4s'}}>
                    <img src='/images/auth/elements/circle-blue.png' alt="graphic" className='w-full' />
                </div>
                <div className='pos-absolute floatingY' style={{zIndex:3,width:25, top:70, left:80, animationDuration:'4s'}}>
                    <img src='/images/auth/elements/circle-purple.png' alt="graphic" className='w-full' />
                </div>
                <div className='pos-absolute floatingYup' style={{zIndex:3,width:30, top:90, right:60, animationDuration:'4s'}}>
                    <img src='/images/auth/elements/circle-white.png' alt="graphic" className='w-full' />
                </div>
            </div>
            {/* <img src="/images/auth/background.png" style={{ marginTop: 60, width: '100%' }} alt="logo" /> */}
            {/* <img src="/logo.png" style={{ height: '100px', width: 'auto', position: 'absolute', top: '120px' }} alt="logo" /> */}
            <div maxWidth="xs" className="flex-col pos-relative">
                {/* style={{ position: 'fixed', top: 310, left: 0, right: 0, height: '100%' }} */}
                <div className='textfield-border' style={{ marginTop: -47,}}>
                    <div className=' pos-relative textfield-bkg' >
                        <div>
                            {/* style={{maxHeight:'calc(100vh - 361px', overflowY:'auto'}} */}
                            <div className='pos-absolute' style={{ width: 73, top: -41, zIndex: 2, transform: 'rotate(325deg)', right: 30, filter: 'drop-shadow(0 4px 6px #0008)' }}>
                                <img src='/images/wallet/coin-w-theme.png' alt="coin" />
                            </div>
                            <p className='p-t-35 fs-21 txt-white' style={{ fontWeight: 'bold' }}>
                                {t('title.login')}
                            </p>
                            {/* <p className='fs-15 lh-14' style={{ color: theme.palette.textColor }} >Lorem ipsum dolor sit amet consectetur.</p> */}
                            <Box component="form" onSubmit={loginUser} >
                                <Grid container style={{ marginTop: 5 }}>
                                    <Grid item xs={12}>
                                        <div>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                placeholder={t('user.username')}
                                                type="username"
                                                autoComplete="username"
                                                color='white'
                                                value={state.username}
                                                onChange={({ target }) => setState({ ...state, username: target.value })}
                                                helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                                                error={inputErrors && inputErrors.username ? true : false}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <FiUser className="img-style fs-18" style={{ color: '#ffffff' }} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div style={{ borderRadius: 30 }}>
                                            <TextField
                                                variant="outlined"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                            >
                                                                {state.showPassword ? <FiEye className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <FiEyeOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <FiLock className="img-style fs-18" style={{ color: '#ffffff' }} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                fullWidth
                                                placeholder={t('user.password')}
                                                type={state.showPassword ? 'text' : 'password'}
                                                value={state.password}
                                                onChange={({ target }) => setState({ ...state, password: target.value })}
                                                helperText={inputErrors && inputErrors.password ? inputErrors.password : ''}
                                                error={inputErrors && inputErrors.password ? true : false}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} style={{ marginTop: 30 }}>
                                        <Button variant="contained" type="submit" fullWidth ><p className='txt-upper' style={{ fontWeight: 600 }}>{t('button.login')}</p></Button>
                                    </Grid>
                                    <Grid item xs={12} style={{ paddingTop: 20, display: 'flex', justifyContent: 'center' }}>
                                        <Link component={RouterLink} to={`/forgot-password`} state={{ from: storePaths(location) }} style={{ textDecoration: 'none', marginBottom: '10px' }} ><Typography style={{ color: '#5CE1E6' }}>{t('login.forgotPassword')}</Typography> </Link>
                                    </Grid>
                                    <Grid item xs={12} style={{ paddingTop: 20, display: 'flex', justifyContent: 'center', alignItems: 'baseline' }}>
                                        <p className='fs-15 lh-14' style={{ color: theme.palette.textColor }}>{t('login.noAccount')}</p>
                                        <Link component={RouterLink} to={`/register`} state={{ from: storePaths(location) }} style={{ textDecoration: 'none', marginBottom: '10px' }} ><Typography className='p-l-5' style={{ color: '#5CE1E6' }}>{t('login.signUp')}</Typography> </Link>
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                    </div>
                </div>
                
            </div>
        </Box>
    );
}